import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image"
import Layout from "../components/layout";
import SEO from "../components/seo";
import icon from "../images/icon-residents.svg";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';



const Residents = ({ data }) => {
  const Residents = data.allContentfulResident.edges;
  return (
    <Layout>
      <SEO title="Lawtech Hub Residents" />
      <div className="">
        <Img
          className="object-cover w-full h-64"
          fluid={data.file.childImageSharp.fixed}
          alt="Header image"
        />
      </div>
      <div className="container">
      <h1 className="title">Residents</h1>
      </div>
      <div className="bg-white w-full border-t-2 border-b border-white">
        <div className="container flex py-6 my-2 self-center">
          <div className="w-auto lg:w-1/2 self-center">
            <h3 className="pt-6 text-center md:text-left">
              {documentToReactComponents(data.contentfulPageIntro.intro.json)}
            </h3>
          </div>
          <div className="w-1/2 hidden lg:block">
            <img src={icon} className="h-64 mb-0 float-right mx-12" alt="LawTech Hub Icons" />
          </div>
        </div>
      </div>




      <div className="container mt-12">




        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 -mx-2 my-24">
      {Residents.map(({ node: post }) => (


            <Link to={`/residents/${post.slug}`} key={post.id} className="hover-card flex bg-white shadow-sm mb-16 mx-2 rounded-sm">

              <div className="relative px-8 pb-8 pt-10 text-xs text-center leading-4">

                <div className="relative mx-auto h-24 w-24 -mt-20 border-gray-100 flex rounded-full border-4 bg-white justify-center items-center">
                  <Img
                    className="logo block"
                    fixed={post.logo.fixed}
                    title={post.companyName}
                    alt={post.companyName}

                  />
                </div>
                <div className="text-sm font-extrabold text-purple-800 mt-6 mb-2">{post.companyName}</div>
                <div className="my-8 leading-5">{post.pitch.pitch}</div>
              </div>
            </Link>




          ))}

   



        </div>
      </div>



    </Layout>
  );
};
export default Residents;



export const query = graphql`
  query residentsIndexPageQuery {
    contentfulPageIntro(page: { eq: "Residents" }) {
      intro {
        json
      } 
    }
    allContentfulResident(sort: {fields: companyName, order: ASC}) {
      edges {
        node {
          id
          slug
          companyName
          pitch {
            pitch
          }
          logo {
            fixed(width: 75) {
              base64
              src
              srcSet
              width
              height
            }
          }
        }
      }
    }
    file(relativePath: { eq: "banner-residents.jpg" }) {
      childImageSharp {
        fixed(width: 1200) {
          base64
          width
          height
          src
          srcSet
        }
      }
    }    
  }



`;